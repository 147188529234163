import mitt from 'mitt'
import config from '@/config'
const emitter = mitt()

export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => {
    if (config.DEBUG_EVENT_BUS) {
      // eslint-disable-next-line no-console
      console.log('EventBus emit', ...args)
    }
    return emitter.emit(...args)
  },
}
