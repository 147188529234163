export const SET_ISSUE_TYPE = 'SET - Market issue type'
export const SET_CLASSIFICATION = 'SET - Classification view'
export const SET_INITIALISING = 'COMPARABLES - Set Initialising'

export default {
  [SET_ISSUE_TYPE](state, payload) {
    state.issueType = payload
  },
  [SET_CLASSIFICATION](state, payload) {
    state.classificationSelected = payload
  },
  [SET_INITIALISING](state, payload) {
    state.initialising = payload
  },
}
