import { SET_ISSUE_TYPE, SET_CLASSIFICATION, SET_INITIALISING } from './mutations'

// Libs
import gQLMixin, { handleGraphQLErr } from '@/mixins/graphqlMixin.js'

// Graph QL
import * as gqlQuery from './queries.graphql'

export default {
  setIssueType({ commit }, payload) {
    commit(SET_ISSUE_TYPE, payload)
  },
  setClassificationView({ commit }, payload) {
    commit(SET_CLASSIFICATION, payload)
  },
  async getComparables(unused, payload) {
    if (!payload) {
      return
    }
    let query = 'GetCompanyComparables'
    const { compsType } = payload

    if (compsType) {
      if (compsType === 'sector') {
        query = 'GetSectorComparables'
      } else if (compsType === 'market') {
        query = 'GetMarketComparables'
      }
    }

    try {
      const {
        data: { comparables },
      } = await gQLMixin.methods.communicate(gqlQuery[query], payload)

      return comparables
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },
  setComparablesInitialisationStatus({ commit }, payload) {
    commit(SET_INITIALISING, payload)
  },
}
