import { redirectPipelineBatchDiffSchema } from '@/router/routes/legacyRouteRedirects'
const routes = [
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/pages/Admin.vue'),
    children: [
      {
        name: 'Batches',
        path: 'batches',
        redirect: '/admin/batches/list',
        component: () => import('@/pages/PassThrough.vue'),
        meta: {
          title: 'Batches',
          auth: true,
          admin: true,
        },
        children: [
          {
            path: 'list',
            name: 'BatchList',
            redirect: '/admin/batches/list/pipeline',
            component: () => import('@/pages/admin/batches/list/Index.vue'),
            meta: {
              title: 'Batch Lists',
              auth: true,
              admin: true,
            },
            children: [
              {
                path: 'pipeline',
                name: 'PipelineList',
                component: () => import('@/pages/admin/batches/list/Pipeline.vue'),
                meta: {
                  title: 'Pipeline Batch List',
                  description: 'Pipeline runs',
                  auth: true,
                  admin: true,
                },
              },
              {
                path: 'market-data',
                name: 'MarketDataList',
                component: () => import('@/pages/admin/batches/list/MarketData.vue'),
                meta: {
                  title: 'Market Data Batch List',
                  description: 'Market data runs',
                  auth: true,
                  admin: true,
                },
              },
              {
                path: 'on-demand',
                name: 'OnDemandList',
                component: () => import('@/pages/admin/batches/list/OnDemand.vue'),
                meta: {
                  title: 'On-Demand Batch List',
                  description: 'On-demand runs',
                  auth: true,
                  admin: true,
                },
              },
            ],
          },
          {
            path: 'diff',
            name: 'BatchDiff',
            redirect: '/admin/batches/diff/pipeline',
            component: () => import('@/pages/admin/batches/diff/Index.vue'),
            meta: {
              title: 'Batch Diffs',
              auth: true,
              admin: true,
            },
            children: [
              {
                path: 'pipeline',
                name: 'PipelineDiff',
                component: () => import('@/pages/admin/batches/diff/Pipeline.vue'),
                beforeEnter: redirectPipelineBatchDiffSchema,
                meta: {
                  title: 'Pipeline Batch Diff',
                  description: 'Pipeline runs',
                  auth: true,
                  admin: true,
                },
                children: [
                  {
                    path: ':batchA...:batchB',
                    name: 'PipelineDiffList',
                    component: () => import('@/components/admin/batches/diff/PipelineDiffList.vue'),
                    redirect: (to) => ({
                      name: 'CompaniesDiff',
                      params: {
                        batchA: to.params.batchA,
                        batchB: to.params.batchB,
                      },
                    }),
                    meta: {
                      title: 'Pipeline Batch Diff',
                      description: 'Pipeline runs',
                      auth: true,
                      admin: true,
                    },
                    children: [
                      {
                        path: 'documents',
                        name: 'DocumentsDiff',
                        component: () => import('@/components/admin/batches/diff/PipelineDiffDocuments.vue'),
                        meta: {
                          title: 'Documents',
                          auth: true,
                          admin: true,
                        },
                      },
                      {
                        path: 'companies',
                        name: 'CompaniesDiff',
                        component: () => import('@/components/admin/batches/diff/PipelineDiffCompanies.vue'),
                        meta: {
                          title: 'Companies',
                          auth: true,
                          admin: true,
                        },
                        props: {
                          default: true,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: 'market-data',
                name: 'MarketDataDiff',
                component: () => import('@/pages/admin/batches/diff/MarketData.vue'),
                meta: {
                  title: 'Market Data Batch Diff',
                  description: 'Market data runs',
                  auth: true,
                  admin: true,
                },
              },
            ],
          },
          {
            path: 'details/:batch',
            redirect: (to) => ({ name: 'Batch Companies', params: { batch: to.params.batch } }),
            component: () => import('@/pages/admin/batches/_batch.vue'),
            props: true,
            meta: {
              title: 'Batch detail',
              auth: true,
              admin: true,
            },
            children: [
              {
                name: 'Batch Companies',
                path: 'companies',
                component: () => import('@/components/admin/batches/JobCompanyDetail.vue'),
                meta: {
                  title: 'Batch Companies',
                  auth: true,
                  admin: true,
                },
              },
              {
                name: 'Batch Documents',
                path: 'documents',
                component: () => import('@/components/admin/batches/JobDetail.vue'),
                props: {
                  default: true,
                },
                meta: {
                  title: 'Batch Documents',
                  auth: true,
                  admin: true,
                },
              },
            ],
          },
        ],
      },
      {
        name: 'Documents',
        path: 'documents',
        component: () => import('@/pages/admin/Documents.vue'),
        meta: {
          title: 'Documents',
          auth: true,
          admin: true,
        },
      },
      {
        name: 'DocumentTools',
        path: 'documents/tools',
        component: () => import('@/pages/admin/ToolsPage.vue'),
        redirect: { name: 'DocumentWatermarkRemoval' },
        children: [
          {
            name: 'DocumentWatermarkRemoval',
            path: 'watermark',
            component: () => import('@/pages/admin/documentTools/WatermarkRemoval.vue'),
            meta: {
              title: 'Document Watermark Removal',
              auth: true,
              admin: true,
            },
          },
          {
            name: 'DocumentPassword',
            path: 'password',
            component: () => import('@/pages/admin/documentTools/PasswordRemovalPage.vue'),
            meta: {
              title: 'Document Password Removal',
              auth: true,
              admin: true,
            },
          },
          {
            name: 'DocumentSplit',
            path: 'split',
            component: () => import('@/pages/admin/documentTools/SplitPage.vue'),
            meta: {
              title: 'Document Split',
              auth: true,
              admin: true,
            },
          },
        ],
        meta: {
          title: 'Document Tools',
          auth: true,
          admin: true,
        },
      },
      {
        name: 'DocEditor',
        path: 'documents/:document',
        component: () => import('@/router/routes/admin/DocEditorWrapper.vue'),
        meta: {
          title: 'Document Editor',
          auth: true,
          admin: true,
        },
        children: [
          {
            alias: '',
            path: 'edit',
            component: () => import('@/components/admin/documents/editor/DocumentEditor.vue'),
            meta: {
              title: 'Document Editor',
              auth: true,
              admin: true,
            },
          },
        ],
      },
      {
        name: 'AdminCompanies',
        path: 'companies',
        component: () => import('@/pages/admin/CompaniesPage.vue'),
        meta: {
          title: 'Companies',
          auth: true,
          admin: true,
        },
      },
      {
        path: 'companies/distribution',
        name: 'CompanyStages',
        component: () => import('@/pages/admin/CompanyStages.vue'),
        meta: {
          title: 'Company data stages',
          auth: true,
          admin: true,
        },
      },
      {
        name: 'Company Edit',
        path: 'companies/:company',
        redirect: (to) => ({ name: 'Edit', params: { company: to.params.company } }),
        component: () => import('@/pages/admin/ModalPage.vue'),
        meta: {
          title: 'Company',
          auth: true,
          admin: true,
        },
        children: [
          {
            name: 'Edit',
            path: 'edit/:tab?',
            component: () => import('@/components/admin/companies/CompanyEdit/CompanyEdit.vue'),
            meta: {
              title: 'Company Edit',
              auth: true,
              admin: true,
              default: true,
            },
          },
          {
            name: 'DataChecks',
            path: 'data-checks',
            component: () => import('@/pages/PassThrough.vue'),
            meta: {
              title: 'Company Data Checks',
              auth: true,
              admin: true,
            },
            children: [
              {
                alias: '',
                path: 'view',
                name: 'Data Checks Company Modal View',
                component: () => import('@/components/admin/dataChecks/ViewDataCheck/ViewDataCheck.vue'),
              },
            ],
          },
        ],
      },
      {
        name: 'Queries',
        path: 'queries',
        component: () => import('@/pages/admin/UserQueries.vue'),
        redirect: '/admin/queries/data',
        meta: {
          title: 'User Queries',
          auth: true,
          admin: true,
          dataAnalyst: true,
        },
        children: [
          {
            path: 'data',
            name: 'Data queries',
            component: () => import('@/pages/admin/queries/DataQueries.vue'),
            meta: {
              title: 'Data queries',
              auth: true,
              admin: true,
              dataAnalyst: true,
            },
          },
          {
            path: 'coverage',
            name: 'Coverage requests',
            component: () => import('@/pages/admin/queries/CoverageRequests.vue'),
            meta: {
              title: 'Coverage requests',
              auth: true,
              admin: true,
              dataAnalyst: true,
            },
          },
          {
            path: 'documents',
            name: 'Document uploads',
            component: () => import('@/pages/admin/queries/DocumentUploads.vue'),
            meta: {
              title: 'Document uploads',
              auth: true,
              admin: true,
              dataAnalyst: true,
            },
          },
        ],
      },
      {
        name: 'Data Checks',
        path: 'data-checks/',
        component: () => import('@/pages/admin/DataChecks.vue'),
        meta: {
          title: 'Data Checks',
          auth: true,
          admin: true,
        },
        children: [
          {
            name: 'Run Data Check',
            path: 'run/:company?',
            component: () => import('@/pages/admin/ModalPage.vue'),
            meta: {
              title: 'Run Data Check',
              auth: true,
              admin: true,
            },
            children: [
              {
                alias: '',
                path: 'run',
                component: () => import('@/components/admin/dataChecks/RunDataCheck/RunDataCheck.vue'),
                children: [
                  {
                    name: 'Earnings Summary Email Preview',
                    path: 'earnings-summary',
                    component: () =>
                      import(
                        '@/components/admin/dataChecks/EarningsSummaryEmailPreview/EarningsSummaryEmailPreview.vue'
                      ),
                  },
                ],
              },
            ],
          },
          {
            name: 'Data Checks Detail',
            path: ':batch/details/',
            component: () => import('@/pages/admin/DataChecksDetail.vue'),
            meta: {
              title: 'Data Checks Detail',
              auth: true,
              admin: true,
            },
            children: [
              {
                name: 'Data Checks Detail Modal',
                path: ':company',
                component: () => import('@/pages/admin/ModalPage.vue'),
                meta: {
                  title: 'Data Checks Detail Modal',
                  auth: true,
                  admin: true,
                },
                children: [
                  {
                    alias: '',
                    path: 'view',
                    name: 'Data Checks Detail Modal View',
                    component: () => import('@/components/admin/dataChecks/ViewDataCheck/ViewDataCheck.vue'),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
